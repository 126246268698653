var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.itemsLinks},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("chevron_right")])]},proxy:true}])})],1),_c('v-col',{staticClass:"mx-5",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"mt-0 d-flex"},[_c('div',{staticClass:"titulo-div"}),_c('div',[_c('div',{staticClass:"titulo-sub pr-2"},[_vm._v("Detalle de LargeFaceList")])])])]),_c('v-col',{staticClass:"mx-5 pa-3",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-0 border-table mr-5"},[_c('v-card-title',{staticClass:"mx-3"},[_c('div'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mx-5 borde-tabla",attrs:{"headers":_vm.headers,"items":_vm.itemsEmpleados,"search":_vm.search,"footer-props":{
                        'items-per-page-options':[10,50,100, -1],
                        'items-per-page-text':'Mostrar',
                        'items-per-page-all-text':'Todos',
                    },"items-per-page":10,"item-class":_vm.rowClass,"loading":_vm.loadingTable,"loading-text":'Cargando...'},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{staticClass:"material-icons tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mt-2 pt-3"},[_vm._v("No hay elementos para mostrar.")])]},proxy:true},{key:"no-results",fn:function(){return [_c('p',{staticClass:"mt-2 pt-3"},[_vm._v("No hay elementos para mostrar.")])]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }